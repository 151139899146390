export const environment = {
  production: false,
  ENV: 'DEV',
  API: 'https://claim-dev.apps.rosa.nxb.uw2.aws.vsp.com/reference-inventoryportalapi/inventoryportalitems',
  oauthApiUrl: 'https://api-integration.vsp.com',
  oauthClientId: 'reference-inventoryportal-ui',
  oauthRedirectUrl: 'https://inventory-dev.pub.vsp.com/oauth_callback',
  oauthScopes: 'auth_employee_sso openid profile reference_view',
  openIdUrl: 'https://api-integration.vspglobal.com/idp/userinfo.openid',
  requiredAdGroups: []
};
